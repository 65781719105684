type ListItem = {
  content: string
  items: Array<ListItem>
}

const alignType = ['left', 'right', 'center', 'justify']

export type Block = {
  type: string
  data: {
    text?: string
    level?: number
    caption?: string
    url?: string
    file?: {
      url?: string
    };
    stretched?: boolean
    withBackground?: boolean
    withBorder?: boolean
    items?: Array<string> | Array<ListItem>
    style?: string
    code?: string
    service?: 'vimeo' | 'youtube'
    source?: string
    embed?: string
    width?: number
    height?: number
    link?: string,
    type?: string,
    title?: string,
    alignment?: 'left' | 'right' | 'center' | 'justify'
    align?: 'left' | 'right' | 'center' | 'justify'
  }
}

export type transforms = {
  [key: string]: any
  delimiter(): string
  header(block: Block): string
  paragraph(block: Block): string
  list(block: Block): string
  image(block: Block): string
  quote(block: Block): string
  code(block: Block): string
  embed(block: Block): string
  button(block: Block): string
}

const transforms: transforms = {
  delimiter: () => {
    return '<br/>'
  },

  header: ({ level, data }) => {
    let _level: any = data.level || level
    if (_level === true) {
      _level = 1
    }
    return `<h${_level}>${data.text}</h${_level}>`
  },

  paragraph: ({ data }) => {
    const paragraphAlign = data.alignment || data.align

    if (typeof paragraphAlign !== 'undefined' && alignType.includes(paragraphAlign)) {
      return `<p style="text-align:${paragraphAlign};">${data.text}</p>`
    } else {
      return `<p>${data.text}</p>`
    }
  },

  list: ({ data }) => {
    const listStyle = data.style === 'unordered' ? 'ul' : 'ol'

    const recursor = (items: any, listStyle: string) => {
      const list = items.map((item: any) => {
        if (!item.content && !item.items) return `<li>${item}</li>`

        let list = ''
        if (item.items) {
          list = recursor(item.items, listStyle)
        }
        if (item.content) {
          return `<li> ${item.content} ${list} </li>`
        }
      })

      return `<${listStyle}>${list.join('')}</${listStyle}>`
    }

    return recursor(data.items, listStyle)
  },

  image: ({ data }) => {
    const caption = data.caption ? data.caption : 'Image'
    return `<img src="${
      data.file && data.file.url ? data.file.url : data.url
    }" alt="${caption}" />`
  },

  quote: ({ data }) => {
    return `<blockquote>${data.text}</blockquote> - ${data.caption}`
  },

  code: ({ data }) => {
    return `<pre><code>${data.code}</code></pre>`
  },

  embed: ({ data }) => {
    switch (data.service) {
      case 'vimeo':
        return `<iframe src="${data.embed}" height="${data.height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
      case 'youtube':
        return `<iframe width="${data.width}" height="${data.height}" src="${data.embed}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      default:
        throw new Error(
          'Only Youtube and Vime Embeds are supported right now.'
        )
    }
  },

  button: ({ data }) => {
    return `<div class="button ${data.type} ${data.alignment}"><a href="${data.link}">${data.title}</a></div>`
  }
}

export default transforms
